import { StoryblokComponent } from '@storyblok/react'
import { useTranslation } from 'next-i18next'
import { Dialog } from 'ui/src/components/Dialog/Dialog'
import { LargeDialog } from 'ui/src/components/Dialog/LargeDialog'
import { yStack, Text, visuallyHidden } from 'ui'
import { useProductData } from '@/components/ProductData/ProductDataProvider'
import { usePriceCalculatorNonTierCoverageInfo } from '../../priceCalculatorAtoms'
import { InfoButton } from './InfoButton/InfoButton'
import { dialogContent } from './NonTierCoverageInfo.css'

export function NonTierCoverageInfo() {
  const productData = useProductData()
  const { t } = useTranslation('purchase-form')
  const coverageInfo = usePriceCalculatorNonTierCoverageInfo()

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <InfoButton>
          <div className={yStack()}>
            <Text as="div" size="xs">
              {t('COVERAGE_DIALOG_TRIGGER_HEADER')}
            </Text>
            <Text as="span" color="textSecondary" size="xs">
              {t('COVERAGE_DIALOG_TRIGGER_DESCRIPTION', {
                productNameFull: productData.displayNameFull,
              })}
            </Text>
          </div>
        </InfoButton>
      </Dialog.Trigger>
      <LargeDialog.Content className={dialogContent} closeOnPointerDownOutside={true}>
        <LargeDialog.Header className={visuallyHidden}>
          {t('COVERAGE_DIALOG_TRIGGER_HEADER')}
        </LargeDialog.Header>
        <LargeDialog.ScrollableInnerContent>
          {coverageInfo.map((blok) => (
            <StoryblokComponent key={blok._uid} blok={blok} nested={true} />
          ))}
        </LargeDialog.ScrollableInnerContent>
      </LargeDialog.Content>
    </Dialog.Root>
  )
}
