import { LargeDialog } from 'ui/src/components/Dialog/LargeDialog'
import { sprinkles } from 'ui/src/theme/sprinkles.css'
import { useTierComparison } from '@/appComponents/TierComparison/TierComparisonProvider'
import { DesktopComparisonTable } from '@/components/ComparisonTable/DesktopComparisonTable'
import { MobileComparisonTable } from '@/components/ComparisonTable/MobileComparisonTable'
import { tierComparisonToTable } from '@/components/ComparisonTable/tierComparisonToTable'
import { useResponsiveVariant } from '@/utils/useResponsiveVariant'

type Props = {
  title: string
  subtitle?: string
  selectedTermsVersion?: string
  showCoverageText?: boolean
}

export function TierComparisonDialogContent({
  title,
  subtitle,
  selectedTermsVersion,
  showCoverageText,
}: Props) {
  const variant = useResponsiveVariant('sm')
  const tierComparison = useTierComparison()
  const { table, selectedTierDisplayName } = tierComparisonToTable(tierComparison, {
    selectedTermsVersion,
    showCoverageText,
  })
  return (
    <LargeDialog.Content closeOnPointerDownOutside={true}>
      <LargeDialog.Header as="h2">{title}</LargeDialog.Header>
      {subtitle && (
        <LargeDialog.Header
          as="h3"
          color="textSecondary"
          className={sprinkles({ marginBottom: 'md' })}
        >
          {subtitle}
        </LargeDialog.Header>
      )}
      <LargeDialog.ScrollableInnerContent>
        {variant === 'mobile' && (
          <MobileComparisonTable {...table} defaultSelectedColumn={selectedTierDisplayName} />
        )}
        {variant === 'desktop' && (
          <DesktopComparisonTable {...table} selectedColumn={selectedTierDisplayName} />
        )}
      </LargeDialog.ScrollableInnerContent>
    </LargeDialog.Content>
  )
}
