import { clsx } from 'clsx'
import { motion } from 'framer-motion'
import { type ComponentProps, forwardRef, type ReactNode } from 'react'
import { PlusInCircleIcon } from 'ui/src/icons/PlusInCircleIcon'
import { tokens } from 'ui/src/theme/theme.css'
import { framerTransitions } from 'ui/src/theme/transitions'
import { infoButton, infoPlusIcon } from './InfoButton.css'

type Props = {
  children: ReactNode
} & ComponentProps<typeof motion.button>

export const InfoButton = forwardRef<HTMLButtonElement, Props>(
  ({ children, className, onClick, ...forwardedProps }, ref) => {
    return (
      <motion.button
        ref={ref}
        className={clsx(infoButton, className)}
        onClick={onClick}
        whileHover={{ backgroundColor: tokens.colors.surfaceOpaquePrimary }}
        transition={framerTransitions.defaultHover}
        {...forwardedProps}
      >
        {children}
        <PlusInCircleIcon size="1.5rem" className={infoPlusIcon} />
      </motion.button>
    )
  },
)
InfoButton.displayName = 'InfoButton'
