import { forwardRef } from 'react'
import { Text } from 'ui'
import { root } from './DiscountTooltip.css'

export type DiscountTooltipProps = {
  children?: string
  subtitle?: string
  color?: 'green' | 'gray'
  orientation?: 'top' | 'bottom'
}

export const DiscountTooltip = forwardRef<HTMLDivElement, DiscountTooltipProps>(
  ({ children, subtitle, color = 'green', orientation = 'top' }, ref) => {
    return (
      <div ref={ref} className={root[color]} data-orientation={orientation}>
        {children && (
          <Text size="xs" color="textPrimary" align="center">
            {children}
          </Text>
        )}
        {subtitle && (
          <Text size="xs" color="textTranslucentSecondary" align="center">
            {subtitle}
          </Text>
        )}
      </div>
    )
  },
)
DiscountTooltip.displayName = 'DiscountTooltip'
