'use client'
import { useHydrateAtoms } from 'jotai/utils'
import { type ReactNode } from 'react'
import {
  priceCalculatorDeductibleInfoAtom,
  priceCalculatorNonTierCoverageInfoAtom,
} from '@/features/priceCalculator/priceCalculatorAtoms'
import type { PriceCalculatorPageStory } from '@/services/storyblok/storyblok'

type Props = {
  story: PriceCalculatorPageStory
  children: ReactNode
}

export function PriceCalculatorStoryProvider({ story, children }: Props) {
  useHydrateAtoms([
    [priceCalculatorDeductibleInfoAtom, story.content.deductibleInfo ?? null],
    [priceCalculatorNonTierCoverageInfoAtom, story.content.nonTierCoverageInfo ?? null],
  ])
  return children
}
