import type { IconRootProps } from './Root'
import { IconRoot } from './Root'

export const PlusInCircleIcon = ({
  size = '1.5rem',
  color = 'currentColor',
  ...props
}: IconRootProps) => {
  return (
    <IconRoot
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      size={size}
      {...props}
    >
      <circle cx="12" cy="12" r="8.75" stroke={color} strokeWidth="1.5" fill="none" />
      <path d="M12 7V17" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M17 12L7 12" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
    </IconRoot>
  )
}
