'use client'
import clsx from 'clsx'
import type { Variants } from 'framer-motion'
import { AnimatePresence, motion } from 'framer-motion'
import { type ReactNode } from 'react'
import { CrossIcon } from 'ui/src/icons/CrossIcon'
import { Badge, framerTransitions, Heading, Text, yStack, sprinkles } from 'ui'
import { ButtonNextLink } from '@/components/ButtonNextLink'
import { Pillow } from '@/components/Pillow/Pillow'
import { useProductData } from '@/components/ProductData/ProductDataProvider'
import { DiscountTooltip } from '@/components/ProductPage/PurchaseForm/DiscountTooltip/DiscountTooltip'
import { useDiscountTooltipProps } from '@/components/ProductPage/PurchaseForm/DiscountTooltip/useDiscountTooltipProps'
import {
  useIsPriceIntentStateReady,
  usePriceIntent,
} from '@/components/ProductPage/PurchaseForm/priceIntentAtoms'
import { useSelectedOffer } from '@/components/ProductPage/PurchaseForm/useSelectedOffer'
import { BUNDLE_DISCOUNT_ELIGIBLE_PRODUCT_IDS } from '@/features/bundleDiscount/bundleDiscount.constants'
import { BundleDiscountOfferTooltip } from '@/features/bundleDiscount/components/BundleDiscountOfferTooltip'
import { type ProductOfferFragment } from '@/services/graphql/generated'
import { useShopSessionValueOrThrow } from '@/services/shopSession/ShopSessionContext'
import { useFormatter } from '@/utils/useFormatter'
import {
  mobileCloseButton,
  pillow,
  pillowWrapper,
  priceLabel,
  priceWrapper,
  productHeroWrapper,
  subTypeBadge,
  subTypeLabel,
} from './ProductHeroV2.css'
import { StickyProductHeader } from './StickyProductHeader/StickyProductHeader'
import { useCondensedProductHero } from './useCondensedProductHero'

const TRAVEL_DISTANCE = '1em'
const ANIMATION: Variants = {
  pushDown: { y: TRAVEL_DISTANCE, opacity: 0 },
  original: { y: 0, opacity: 1 },
  pushUp: { y: `-${TRAVEL_DISTANCE}`, opacity: 0 },
}
const TRANSITION = { duration: 0.3, ...framerTransitions.easeInOutCubic }

export function ProductHeroV2() {
  const formatter = useFormatter()
  const productData = useProductData()
  const [selectedOffer] = useSelectedOffer()
  const hasTiers = productData.variants.length > 1
  const subType = hasTiers ? selectedOffer?.variant.displayNameSubtype : undefined
  const isPriceIntentReady = useIsPriceIntentStateReady()

  const shouldShowDiscountHighlight = isPriceIntentReady && selectedOffer != null

  const productHeading = (
    <>
      <Heading as="h1" variant="standard.18">
        {productData.displayNameFull}
        {subType && <span className={subTypeLabel}>{` ${subType}`}</span>}
      </Heading>

      {!selectedOffer && (
        <Text color="textSecondary" size="md">
          {productData.tagline}
        </Text>
      )}

      {selectedOffer && (
        <div className={priceWrapper}>
          <AnimatePresence initial={false}>
            <motion.div
              key={selectedOffer.id}
              className={priceLabel}
              transition={TRANSITION}
              variants={ANIMATION}
              initial="pushDown"
              animate="original"
              exit="pushUp"
            >
              <Text color="textSecondary" size="md">
                {formatter.monthlyPrice(selectedOffer.cost.net)}
              </Text>
            </motion.div>
          </AnimatePresence>
        </div>
      )}
    </>
  )

  return (
    <>
      <StickyProductHeader>
        {
          <>
            <Pillow size="small" {...productData.pillowImage} priority={true} />
            <div className={sprinkles({ textAlign: 'left' })}>{productHeading}</div>
            <ButtonNextLink
              className={mobileCloseButton}
              href={productData.pageLink}
              variant="secondary"
              Icon={<CrossIcon size="1.5rem" />}
              size="icon"
            />
          </>
        }
      </StickyProductHeader>

      <ProductHeroPillow subType={subType}>
        <div className={yStack({ gap: 'md' })}>
          <div className={sprinkles({ textAlign: 'center' })}>{productHeading}</div>
          {shouldShowDiscountHighlight && <DiscountHighlight selectedOffer={selectedOffer} />}
        </div>
      </ProductHeroPillow>
    </>
  )
}

function ProductHeroPillow({ children, subType }: { children: ReactNode; subType?: string }) {
  const { pillowImage } = useProductData()
  const isCondensedProductHero = useCondensedProductHero()

  return (
    <div
      className={clsx(productHeroWrapper.base, isCondensedProductHero && productHeroWrapper.hidden)}
    >
      <div className={pillowWrapper}>
        <Pillow className={pillow} size={{ lg: 'xxlarge' }} {...pillowImage} priority={true} />
        {subType && (
          <Badge className={subTypeBadge} size="responsive">
            {subType}
          </Badge>
        )}
      </div>

      <div className={sprinkles({ position: 'relative', textAlign: 'center' })}>{children}</div>
    </div>
  )
}

function DiscountHighlight({ selectedOffer }: { selectedOffer: ProductOfferFragment }) {
  const shopSession = useShopSessionValueOrThrow()
  const priceIntent = usePriceIntent()
  const discountTooltipProps = useDiscountTooltipProps(
    selectedOffer,
    shopSession.cart.redeemedCampaign ?? undefined,
  )

  if (discountTooltipProps != null) {
    return <DiscountTooltip {...discountTooltipProps} orientation="bottom" />
  } else if (
    shopSession.experiments?.bundleDiscount &&
    BUNDLE_DISCOUNT_ELIGIBLE_PRODUCT_IDS.has(priceIntent.product.id)
  ) {
    return <BundleDiscountOfferTooltip offer={selectedOffer} orientation="bottom" />
  }

  return null
}
