import { atom, useAtomValue } from 'jotai'
import {
  activeFormSectionIdAtom,
  priceIntentAtom,
} from '@/components/ProductPage/PurchaseForm/priceIntentAtoms'
import { getAtomValueOrThrow } from '@/utils/jotaiUtils'
import { priceCalculatorStepAtom } from '../priceCalculatorAtoms'

// The condensed state is used to show the sticky header and hides the large product pillow
export const useCondensedProductHero = () => {
  return useAtomValue(condensedProductHeroAtom)
}

const condensedProductHeroAtom = atom((get) => {
  const priceIntent = get(priceIntentAtom)
  if (priceIntent == null) return false

  const step = getAtomValueOrThrow(get, priceCalculatorStepAtom)
  const activeSectionId = getAtomValueOrThrow(get, activeFormSectionIdAtom)
  return step === 'fillForm' && activeSectionId !== 'ssn-se'
})
